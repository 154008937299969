import z from 'zod'

export const StoreSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
  country_code: z.string().optional(),
  storeId: z.string(),
  email: z.string().nullable().optional(),
  timezone: z.string(),
  open_hour: z.number().optional(),
  close_hour: z.number().optional(),
  reservationHours: z.number(),
  active: z.boolean(),
})
export type Store = z.infer<typeof StoreSchema>

export const StoresSchema = z.array(StoreSchema)
export type Stores = z.infer<typeof StoresSchema>
