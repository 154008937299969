import type { AxiosError } from 'axios'
import axios from 'axios'
import type { DateRange } from 'react-day-picker'

import { env } from '~/env.mjs'
import { getAccessToken } from '~/features/auth/use-user'
import type { SortByOption } from '~/features/filters/sort-by-filter'
import exampleOffer from '~/features/inventory/waiting-room/example-offer.json'
import { languageCodeFromUrlCode } from '~/features/offer/get-articles-price'
import type { OfferImagePayload } from '~/features/offer/types'
import type {
  Configuration,
  Configurations,
  ConfigurationType,
} from '~/schema/configuration'
import { type UnitItem, UnitItemSchema } from '~/schema/unit-item'
import type { UnitItems } from '~/schema/unit-items'
import { UnitItemsSchema } from '~/schema/unit-items'
import type { Store, Stores } from '~/schema/units'
import { StoresSchema } from '~/schema/units'

import type { Activity } from './activity'
import type { AdUser } from './ad-user'
import type { Category } from './category'
import type { Condition } from './condition'
import { getDateRange } from './get-date-range'
import { type Market } from './markets'
import type { Offer, state } from './offer'
import type { TotalProductsByUnit } from './total-products-by-unit'
import { TotalProductsByUnitSchema } from './total-products-by-unit'
import type { Users } from './user'

export const api = {
  axiosInstance: axios.create({
    baseURL: `${env.NEXT_PUBLIC_API_ROOT}`,
  }),
  markets: {
    get: async () => {
      const response = await api.axiosInstance.get<Market[]>(`/markets`, {
        headers: {
          Accept: `application/vnd.ingka.v2+json`,
        },
      })
      return response.data
    },
  },

  defects: {
    post: async (offerNumber: string, token: string, data: FormData) => {
      return api.axiosInstance.post(`/defects/${offerNumber}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  },

  authentication: {
    authenticate: async (
      headersOptions: { Authorization?: string; 'x-client-id': string },
      data?: { code?: string; redirectUrl: string },
    ) => {
      try {
        return api.axiosInstance.post(`/auth/login`, data, {
          headers: { ...headersOptions },
        })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return Promise.reject(axiosError.message)
        } else {
          return Promise.reject('Unknown error')
        }
      }
    },
    refresh: async () => {
      return api.axiosInstance.post(`/auth/refresh`, undefined, {
        headers: { Authorization: getAccessToken() },
      })
    },
  },
  configuration: {
    get: async ({
      countryCode,
      languageCode,
      type,
    }: {
      countryCode: string
      languageCode: string
      type: ConfigurationType
    }) => {
      const url = `/api/${type}/config/${countryCode}/${languageCodeFromUrlCode(languageCode)}`
      const response = await api.axiosInstance.get<Configurations>(url, {
        headers: { Authorization: getAccessToken() },
      })
      return response.data
    },
  },
  coworker: {
    item: {
      get: async ({
        offerId,
        isTourOpen = false,
      }: {
        offerId: string
        isTourOpen?: boolean
      }) => {
        const url = `/api/coworker/item/${offerId}`
        if (isTourOpen) {
          // @ts-ignore this mock needs an update
          return exampleOffer as UnitItem
        }
        const response = await api.axiosInstance.get(url, {
          headers: { Authorization: getAccessToken() },
        })
        try {
          return UnitItemSchema.parse(response.data)
        } catch (error) {
          console.error(error)
          return response.data as UnitItem
        }
      },
    },
    offer: {
      delete: async (sekundId: string) => {
        return api.axiosInstance.delete<UnitItem>(`/offers/${sekundId}`, {
          headers: { Authorization: getAccessToken() },
        })
      },
      post: async (offer: UnitItem) => {
        return api.axiosInstance.post<UnitItem>(
          `/api/coworker/offer/edit`,
          offer,
          { headers: { Authorization: getAccessToken() } },
        )
      },
    },
    reasonsForDiscount: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const url = `${env.NEXT_PUBLIC_API_ROOT}/api/coworker/reasons-for-discount/${countryCode}/${languageCodeFromUrlCode(languageCode)}`
        const response = await fetch(url, {
          headers: { Authorization: getAccessToken() },
        })
        const reasonsDiscount = await response.json()
        return reasonsDiscount
      },
    },
    conditions: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const response = await api.axiosInstance.get<Condition[]>(
          `/product-conditions/${countryCode}/${languageCodeFromUrlCode(languageCode)}`,
          {
            headers: { Authorization: getAccessToken() },
          },
        )
        return response.data
      },
    },
    units: {
      get: async ({ countryCode }: { countryCode: string }) => {
        const url = `/stores/${countryCode}`
        const response = await api.axiosInstance.get(url.toString(), {
          headers: { Authorization: getAccessToken() },
        })
        try {
          return StoresSchema.parse(response.data)
        } catch (error) {
          console.error(error)
          return response.data as Stores
        }
      },
    },
    unit: {
      post: async (store: Partial<Store>) => {
        // TODO: When the API is updated to update only via PATCH, remove this change
        const patchedStore = {
          ...store,
          store_id: store.storeId,
          reserved_hours: store.reservationHours,
        }
        delete patchedStore.storeId
        delete patchedStore.reservationHours

        return api.axiosInstance.post<Store>(`/api/units`, patchedStore, {
          headers: { Authorization: getAccessToken() },
        })
      },
      patch: async (
        storeId: string,
        fieldName: string,
        fieldValue: boolean | string | number,
      ) => {
        let paramFieldName
        switch (fieldName) {
          case 'status':
            paramFieldName = 'active'
            break
          case 'reservationHours':
            paramFieldName = 'reservedHours'
            break
          default:
            paramFieldName = fieldName
        }

        let pathFieldName
        switch (fieldName) {
          case 'reservationHours':
            pathFieldName = 'reservedHours'
            break
          default:
            pathFieldName = fieldName
        }

        return api.axiosInstance.patch<Store>(
          `/stores/${storeId}/${pathFieldName}?${paramFieldName}=${fieldValue}`,
          null,
          {
            headers: { Authorization: getAccessToken() },
          },
        )
      },
    },
  },
  offers: {
    put: async (offer: Offer) =>
      api.axiosInstance.put<Offer>(`/offers`, offer, {
        headers: { Authorization: getAccessToken() },
      }),
    get: async ({
      unitId,
      page = 0,
      states,
      size,
      countryCode,
      languageCode,
      searchValue,
      sortBy,
      dateRange,
    }: {
      token?: string
      unitId: string
      page?: number
      size: number
      states: UnitItem['state'][]
      countryCode?: string
      languageCode: string
      searchValue?: string
      sortBy: SortByOption
      dateRange?: DateRange
    }) => {
      if (!countryCode) throw new Error('countryCode is required')
      const storeUrl = new URL(
        `${env.NEXT_PUBLIC_API_ROOT}/api/offers/${countryCode}/${languageCodeFromUrlCode(languageCode)}/${unitId}`,
      )
      if (states) storeUrl.searchParams.append('states', states.join(','))
      storeUrl.searchParams.append('size', size.toString())
      storeUrl.searchParams.append('myItems', 'false')
      storeUrl.searchParams.append('page', page.toString())

      if (dateRange) {
        storeUrl.searchParams.append('dateRange', getDateRange(dateRange))
      }

      const [column, direction] = sortBy.split('-')
      storeUrl.searchParams.append('column', column)
      storeUrl.searchParams.append('direction', direction)
      if (searchValue) {
        storeUrl.searchParams.append('searchCode', searchValue)
      }

      const response = await fetch(decodeURIComponent(storeUrl.toString()), {
        headers: { Authorization: getAccessToken() },
      })
      const data = await response.json()

      try {
        return UnitItemsSchema.parse(data)
      } catch (error) {
        console.error(error)
        return data as UnitItems
      }
    },

    images: {
      get: async (offerNumber: string) => {
        return await api.axiosInstance
          .get<OfferImagePayload>(`/offers/${offerNumber}/images`, {
            headers: { Authorization: getAccessToken() },
          })
          .then((r) => r.data)
      },
    },

    getTotalProductsByUnit: async ({ unitId }: { unitId?: string }) => {
      const response = await api.axiosInstance.get<TotalProductsByUnit>(
        `/offers/${unitId}/count`,
        {
          headers: { Authorization: getAccessToken() },
        },
      )
      try {
        return TotalProductsByUnitSchema.parse(response.data)
      } catch (error) {
        console.error(error)
        return response.data as TotalProductsByUnit
      }
    },
    bySekundId: {
      details: async ({ sekundId }: { sekundId: string }) => {
        const response = await api.axiosInstance.get<Offer>(
          `/offers/${sekundId}/details`,
          {
            headers: { Authorization: getAccessToken() },
          },
        )
        return response.data
      },
      articles: async ({
        sekundId,
        articleNumbers,
      }: {
        sekundId: string
        articleNumbers: string[]
      }) => {
        const response = await api.axiosInstance.put<Offer>(
          `/offers/${sekundId}/articles`,
          { articleNumbers },
          {
            headers: { Authorization: getAccessToken() },
          },
        )
        return response.data
      },

      audit: async ({ sekundId }: { sekundId: string }) => {
        const response = await api.axiosInstance.get<Activity[]>(
          `/offers/${sekundId}/audit`,
          { headers: { Authorization: getAccessToken() } },
        )

        return response.data
      },
      state: async ({
        offerNumber,
        state,
      }: {
        offerNumber: string
        state: state
      }) => {
        const response = await api.axiosInstance.patch<Offer>(
          `/offers/${offerNumber}/state`,
          null,
          {
            params: { state },
            headers: { Authorization: getAccessToken() },
          },
        )
        return response.data
      },
    },
  },
  owner: {
    configurations: {
      post: async (
        input?: Configuration,
        country_code?: string,
        lang_code?: string,
      ) => {
        return api.axiosInstance.post(
          `/api/owner/config/save`,
          [
            {
              ...input,
              country_code,
              lang_code,
            },
          ],
          { headers: { Authorization: getAccessToken() } },
        )
      },
    },
    users: {
      get: async ({
        page = 0,
        size,
        countryCode,
        searchValue,
      }: {
        page?: number
        size: number
        countryCode: string
        searchValue?: string
      }) => {
        const usersUrl = new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/users`)
        usersUrl.searchParams.append('country_code', countryCode)
        usersUrl.searchParams.append('size', size.toString())
        usersUrl.searchParams.append('page', page.toString())
        if (searchValue && searchValue?.trim() !== '')
          usersUrl.searchParams.append('search', searchValue)
        return api.axiosInstance.get<Users>(
          decodeURIComponent(usersUrl.toString()),
          { headers: { Authorization: getAccessToken() } },
        )
      },

      post: async ({
        searchQuery,
        code,
      }: {
        searchQuery: string
        code: string
      }) => {
        const url = new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/users`)

        url.searchParams.append('search', searchQuery)

        return api.axiosInstance.post<AdUser[]>(
          url.toString(),
          {
            code,
          },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        )
      },
    },
    user: {
      post: async ({ code, userId }: { code: string; userId: string }) => {
        const url = decodeURIComponent(
          new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/user`).toString(),
        )

        return api.axiosInstance.post<{}>(
          url,
          { code, userId },
          { headers: { Authorization: getAccessToken() } },
        )
      },

      delete: async ({ code, userId }: { code: string; userId: string }) => {
        const url = decodeURIComponent(
          new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/user`).toString(),
        )

        return api.axiosInstance.delete<{}>(url, {
          data: { code, userId },
          headers: { Authorization: getAccessToken() },
        })
      },
    },
  },
  public: {
    categories: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const response = await api.axiosInstance.get<Category[]>(
          `/api/public/categories/${countryCode}/${languageCodeFromUrlCode(languageCode)}`,
        )
        return response.data
      },
    },
  },
}
